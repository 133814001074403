<template>
  <div>
    <a-modal width="70%" :title="Title" :visible="visible" :ok-button-props="{ danger: true }" :confirm-loading="confirmLoading" @ok="handleOk" @cancel="cancel">
      <a-form v-if="visible" scrollToFirstError ref="formRef" style="height: 600px; overflow-x: auto" :model="formState" :label-col="{ style: { width: '100px' } }" :rules="rules">
        <a-divider style="border-color: #7cb305" orientation="center" dashed>
          <AppstoreAddOutlined /> 基本信息
        </a-divider>
        <a-form-item label="商品名称：" name="name">
          <a-input v-model:value="formState.name" style="width: 60%" placeholder="商品名称" />
        </a-form-item>
        <a-form-item label="商品图片：" name="attachFiles">
          <upload v-if="visible " ref="upload" v-model:attachFiles="formState.attachFiles" :FormData="formState" :listType="'picture-card'" :typeNumber="3" :fileNumber="6"></upload>
          主图默认第一张，共6张
        </a-form-item>
        <a-row>
          <a-col :span="12">
            <a-form-item label="商品分类：" name="categoryId">
              <a-cascader style="width: 60%" v-model:value="formState.categoryId" :field-names="{
                  label: 'name',
                  value: 'categoryId',
                  children: 'children',
                }" :options="options" placeholder="请选择" change-on-select />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="商品上架：" name="onSale">
              <a-radio-group v-model:value="formState.onSale">
                <a-radio :value="1">上架</a-radio>
                <a-radio :value="2">下架</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="虚拟销量：">
              <a-input-number v-model:value="formState.salesVolume" placeholder="虚拟销量" style="width: 60%" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="运费模板：" name="freightTemplateId">
              <a-select v-model:value="formState.freightTemplateId" allowClear placeholder="请选择" style="width: 300px" :options="freightOptions" />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="保障服务:" name="goodsEnsures" placeholder="保障服务">
              <dictData v-model:dictValue="formState.goodsEnsures" :textValue="''" :dict="'1646047581237211138'" :mode="'multiple'" :source="1"></dictData>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="卖点:" name="sellPoint" placeholder="卖点">
              <dictData v-model:dictValue="formState.sellPoint" :dict="'1648886135910928385'" :source="1"></dictData>
            </a-form-item>
            <!-- <a-form-item label="发货地：" name="userAddressId">
              <a-select
                v-model:value="formState.userAddressId"
                allowClear
                placeholder="请选择"
                style="width: 300px"
                :options="AddressControllerOptions"
              />
            </a-form-item> -->
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-item label="热卖商品：" name="hotSellState">
              <a-radio-group v-model:value="formState.hotSellState">
                <a-radio value="0">否</a-radio>
                <a-radio value="1">是</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="新品上架：" name="newProductState">
              <a-radio-group v-model:value="formState.newProductState">
                <a-radio value="0">否</a-radio>
                <a-radio value="1">是</a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-row>
        <a-divider style="border-color: #7cb305" orientation="center" dashed>
          <ControlOutlined /> 规格信息
        </a-divider>
        <div>
          <a-tabs type="card">
            <a-tab-pane key="1" tab="商品规格">
              <div v-for="(item, index) in formState.goodsSkuSpecValues" :key="index">
                <div style="border-top: 1px solid #eee; padding: 10px">
                  <PlusSquareOutlined style="float: right; cursor: pointer" v-if="index == 0" @click="handleAdd()" />
                  <MinusSquareOutlined style="float: right; cursor: pointer" v-else @click="handleDel(index)" />
                  <a-row>
                    <a-col :span="8">
                      <a-form-item label="商品规格：" :name="['goodsSkuSpecValues', index, 'goodsSpecId']" :rules="{
                          required: true,
                          message: '不能为空',
                          trigger: 'blur',
                        }">
                        <a-select v-model:value="item.goodsSpecId" @change="goodsSpecChange(item.goodsSpecId, index)" allowClear placeholder="请选择" style="width: 80%" :options="getOptions()" />
                      </a-form-item>
                    </a-col>
                    <a-col :span="16">
                      <a-form-item v-if="item.goodsSpecId" label="添加规格值：" :name="['goodsSkuSpecValues', index, 'goodsSpecValueIds']" :rules="{
                          required: true,
                          type: 'array',
                          message: '不能为空',
                          trigger: 'blur',
                        }">
                        <a-select v-model:value="item.goodsSpecValueIds" allowClear mode="multiple" placeholder="请选择" style="width: 90%" :options="item.optionsSecond" @change="goodsSpecValue()" />
                      </a-form-item>
                    </a-col>
                  </a-row>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
          <a-tabs type="card">
            <a-tab-pane key="1" tab="商品SUK">
              <div style="padding: 10px">
                <div style="padding:10px;">
                  批量导入:
                  <a-row>
                    <a-col :span="12">
                      销售价：
                      <a-input-number v-model:value="priceInitial" :min="0" style="width:80%" />
                    </a-col>
                    <a-col :span="12">
                      库存：
                      <a-input-number v-model:value="storeInitial" :min="0" style="width:70%" />
                      <a-button type="default" @click="InitialClick" style="margin-left:10px">
                        确认
                      </a-button>
                    </a-col>
                  </a-row>
                </div>
                <a-table :dataSource="dataSource" :pagination="false" :columns="columns">
                  <template #price="{  record }">
                    <a-input-number v-model:value="record.price" :min="0" />
                  </template>
                  <template #store="{  record }">
                    <a-input-number v-model:value="record.store" :min="0" />
                  </template>
                </a-table>
                <p style="text-align: right;">共{{ dataSource.length }}条</p>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
        <a-divider style="border-color: #7cb305" orientation="center" dashed>
          <PropertySafetyOutlined /> 积分赠送
        </a-divider>
        <a-form-item label="是否开启：" name="pointsGiveSwitch">
          <a-radio-group v-model:value="formState.pointsGiveSwitch">
            <a-radio value="0">关闭</a-radio>
            <a-radio value="1">开启</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="赠送数量：" name="pointsGiveNum" v-if="formState.pointsGiveSwitch == '1'">
          <a-input-number v-model:value="formState.pointsGiveNum" style="width: 60%" placeholder="赠送数量" />
        </a-form-item>
        <a-divider style="border-color: #7cb305" orientation="center" dashed>
          <WalletOutlined /> 积分抵扣
        </a-divider>
        <a-form-item label="是否开启：" name="pointsDeductSwitch">
          <a-radio-group v-model:value="formState.pointsDeductSwitch">
            <a-radio value="0">关闭</a-radio>
            <a-radio value="1">开启</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="抵扣金额：" name="pointsDeductAmount" v-if="formState.pointsDeductSwitch == '1'">
          <a-input-number v-model:value="formState.pointsDeductAmount" style="width: 60%" placeholder="1积分可抵扣金额" />
        </a-form-item>
        <a-divider style="border-color: #7cb305" orientation="center" dashed>
          <ContainerOutlined /> 辅助信息
        </a-divider>
        <a-form-item label="商品详情：">
          <wangEditor v-model:comment="formState.specifics"></wangEditor>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import {
  GoodsController_post,
  GoodsController_put,
  findByTreeList,
  UserAddressController_find,
  FreightTemplatController_find,
  GoodsSpecController_find,
  GoodsSpecValueController_find,
  GoodsController_id
} from '@/api/commodityManagement/allGoods'
import dictData from '@/components/dictData'
import upload from '@/components/upload'
import wangEditor from '@/components/wangEditor'
const form = {
  //热卖商品
  hotSellState: undefined,
  //热卖商品
  newProductState: undefined,
  //运费模板id
  freightTemplateId: undefined,
  //分类id
  categoryId: [],
  //商品名称
  name: undefined,
  //商品主图
  attachFiles: [],
  //商品虚拟销量
  salesVolume: undefined,
  //起售价
  price: undefined,
  //发货地
  userAddressId: undefined,
  //商品详情
  specifics: undefined,
  //积分赠送开关（1开 0关）
  pointsGiveSwitch: undefined,
  //积分赠送数量
  pointsGiveNum: undefined,
  //积分抵扣开关（1开 0关）
  pointsDeductSwitch: undefined,
  //	1积分数可抵多少元
  pointsDeductAmount: undefined,
  //上架状态
  onSale: undefined,
  //规格
  goodsSkuSpecValues: [{ goodsSpecId: undefined, goodsSpecValueIds: [] }],
  //保障服务
  goodsEnsures: [],
  //卖点
  sellPoint: undefined
}
const columnsList = [
  {
    title: '销售价(元)',
    dataIndex: 'price',
    slots: { customRender: 'price' }
  },
  {
    title: '库存',
    dataIndex: 'store',
    slots: { customRender: 'store' }
  }
]
export default defineComponent({
  name: 'index',
  props: {
    Title: {
      type: String,
      required: false
    },
    FormData: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      confirmLoading: false,
      //规格大类
      goodsSpecOptions: [],
      //批量销售
      priceInitial: 0,
      //批量库存
      storeInitial: 0,
      //分类
      options: [],
      //发货地
      AddressControllerOptions: [],
      //运费模板
      freightOptions: [],
      //数据
      dataSource: [],
      //类型
      visible: false,
      arrList: [],
      formState: {
        ...form
      },
      rules: {
        goodsEnsures: [
          {
            required: true,
            type: 'array',
            message: '不能为空',
            trigger: 'blur'
          }
        ],
        sellPoint: [
          {
            required: true,
            message: '不能为空',
            trigger: 'blur'
          }
        ],
        attachFiles: [
          {
            required: true,
            type: 'array',
            message: '不能为空',
            trigger: 'blur'
          }
        ],
        categoryId: [
          {
            required: true,
            type: 'array',
            message: '不能为空',
            trigger: 'blur'
          }
        ],
        onSale: [
          {
            required: true,
            type: 'number',
            message: '不能为空',
            trigger: 'blur'
          }
        ],
        name: [
          {
            required: true,
            message: '不能为空',
            trigger: 'blur'
          }
        ],
        pointsDeductSwitch: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        pointsDeductAmount: [
          {
            required: true,
            type: 'number',
            message: '不能为空',
            trigger: 'blur'
          }
        ],
        pointsGiveSwitch: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        pointsGiveNum: [
          {
            required: true,
            type: 'number',
            message: '不能为空',
            trigger: 'blur'
          }
        ],
        userAddressId: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        freightTemplateId: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        hotSellState: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        newProductState: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ]
      },
      editor: false
    }
  },
  components: {
    upload,
    dictData,
    wangEditor
  },
  created() {
    GoodsSpecController_find().then((res) => {
      this.goodsSpecOptions = res.data.map((res) => {
        return {
          value: res.goodsSpecId,
          label: res.specName,
          disabled: false
        }
      })
    })
    findByTreeList().then((res) => {
      this.options = res.data
    })
    // this.AddressControllerOptions = UserAddressController_find().then((res) => {
    //   res.data.map((res) => {
    //     return {
    //       value: res.id,
    //       label:
    //         res.provinceName + res.cityName + res.countyName + res.detailInfo,
    //     };
    //   });
    // });
    FreightTemplatController_find().then((res) => {
      this.freightOptions = res.data.map((res) => {
        return {
          value: res.freightTemplatId,
          label: res.name
        }
      })
    })
  },
  computed: {
    columns() {
      return [
        ...this.formState.goodsSkuSpecValues.reduce((arr, item) => {
          const data = this.goodsSpecOptions.find(
            (k) => k.value === item.goodsSpecId
          )
          return data
            ? [
                ...arr,
                {
                  title: data.label,
                  dataIndex: data.value
                }
              ]
            : arr
        }, []),
        ...columnsList
      ]
    }
  },
  methods: {
    handleGetDataSource() {
      const arrs = this.formState.goodsSkuSpecValues.filter(
        (item) => !!item.goodsSpecValueIds.length
      )
      if (!arrs.length) {
        this.dataSource = []
        return
      }
      const { goodsSpecId, goodsSpecValueIds = [], optionsSecond } = arrs[0]
      const checkArrs = goodsSpecValueIds.map((item) => ({
        [goodsSpecId]: optionsSecond.find((o) => o.value === item).label,
        goodsSkuSpecValues: [
          { goodsSpecId: goodsSpecId, goodsSpecValueId: item }
        ]
      }))
      const dataSource = this.getArrs(checkArrs, arrs.slice(1))
      this.dataSource = dataSource.map((newItem) => {
        const data = this.dataSource.find((item) => {
          const aJson = JSON.stringify(item).replace('{', '').replace('}', '')
          const bJson = JSON.stringify(newItem)
            .replace('{', '')
            .replace('}', '')
          return aJson.includes(bJson)
        })
        return {
          price: 0,
          store: 0,
          ...newItem,
          ...data
        }
      })
    },
    getArrs(checkArrs, list) {
      let newArr = []
      if (list.length >= 1) {
        const { goodsSpecId, goodsSpecValueIds = [], optionsSecond } = list[0]
        for (let i = 0; i < checkArrs.length; i++) {
          for (let j = 0; j < goodsSpecValueIds.length; j++) {
            newArr.push({
              ...checkArrs[i],
              [goodsSpecId]: optionsSecond.find(
                (o) => o.value === goodsSpecValueIds[j]
              ).label,
              goodsSkuSpecValues: [
                ...checkArrs[i].goodsSkuSpecValues,
                {
                  goodsSpecId: goodsSpecId,
                  goodsSpecValueId: goodsSpecValueIds[j]
                }
              ]
            })
          }
        }
        return this.getArrs(newArr, list.slice(1))
      } else {
        return checkArrs
      }
    },
    InitialClick() {
      this.dataSource.forEach((res) => {
        res.price = this.priceInitial
        res.store = this.storeInitial
      })
    },
    Api(val, index) {
      GoodsSpecValueController_find({ goodsSpecId: val }).then((res) => {
        this.formState.goodsSkuSpecValues[index].optionsSecond = res.data.map(
          (res) => {
            return {
              label: res.goodsSpecValue,
              value: res.goodsSpecValueId
            }
          }
        )
      })
    },
    //大类
    goodsSpecChange(val, index) {
      this.handleGetDataSource()
      this.Api(val, index)
      this.formState.goodsSkuSpecValues[index].goodsSpecValueIds = []
    },
    //小类
    goodsSpecValue() {
      this.handleGetDataSource()
    },
    handleAdd() {
      this.formState.goodsSkuSpecValues.push({
        goodsSpecId: undefined,
        goodsSpecValueIds: []
      })
    },
    handleDel(index) {
      this.formState.goodsSkuSpecValues.splice(index, 1)
      this.handleGetDataSource()
    },
    cancel() {
      this.$refs.formRef.resetFields()
      this.dataSource = []
      this.formState = { ...form }
      this.editor = false
      this.confirmLoading = false
      this.visible = false
    },
    handleOk() {
      this.$refs.formRef
        .validate()
        .then(() => {
          this.confirmLoading = true
          const model = {
            ...this.formState,
            attachFiles: this.formState.attachFiles.map((res) => {
              if (res.response) {
                return {
                  path: res.response.data.fullPath,
                  fileName: res.response.data.name
                }
              } else {
                return {
                  path: res.path,
                  fileName: res.fileName
                }
              }
            }),
            goodsEnsures: this.formState.goodsEnsures.map((res) => {
              return { ensureName: res }
            })
          }
          if (!this.editor) {
            GoodsController_post({
              ...model,
              goodsSkus: this.dataSource
            }).then((res) => {
              this.visible = false
              this.confirmLoading = false
              this.$message.success('操作成功')
              this.cancel()
              this.$parent.refresh()
            })
          } else {
            GoodsController_put({
              ...model,
              goodsSkus: this.dataSource
            }).then((res) => {
              this.visible = false
              this.confirmLoading = false
              this.$message.success('操作成功')
              this.cancel()
              this.$parent.refresh()
            })
          }
        })
        .catch((errorInfo) => {
          this.$refs.formRef.scrollToField(
            errorInfo.errorFields[0].name.toString()
          )
        })
    },
    showModal(val) {
      if (val) {
        this.editor = true
        GoodsController_id(val.goodsId).then((res) => {
          this.formState = { ...form, ...res.data }
          this.formState.categoryId = [
            this.formState.categoryId1,
            this.formState.categoryId2,
            this.formState.categoryId3
          ]
          this.formState.goodsEnsures = this.formState.goodsEnsures.map(
            (res) => {
              return res.ensureName
            }
          )
          this.formState.goodsSkuSpecValues.map((res, index) => {
            this.Api(res.goodsSpecId, index)
          })
          this.dataSource = this.formState.goodsSkus.map((res) => {
            const goodsSkuSpecValues = res.goodsSkuSpecValues.map((res) => {
              return {
                goodsSpecId: res.goodsSpecId,
                goodsSpecValueId: res.goodsSpecValueId
              }
            })
            const object = res.goodsSkuSpecValues.map((res) => {
              return {
                [res.goodsSpecId]: res.goodsSpecValueName
              }
            })
            const objectList = {}
            object.forEach((res) => {
              for (let i in res) {
                objectList[i] = res[i]
              }
            })
            return {
              ...objectList,
              price: res.price,
              store: res.store,
              goodsSkuSpecValues
            }
          })
          this.visible = true
        })
      } else {
        this.editor = false
        this.formState = { ...form }
        this.visible = true
      }
    },
    getOptions() {
      const checkIds = this.formState.goodsSkuSpecValues.map(
        (item) => item.goodsSpecId
      )
      return this.goodsSpecOptions.map((item) => ({
        ...item,
        disabled: checkIds.includes(item.value)
      }))
    }
  }
})
</script>
<style lang="less">
.page-wrapper {
  padding: 20px 32px;
}

.radio {
  width: 100px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #e0e0e2;
}
</style>
