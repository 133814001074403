<template>
  <!-- <dictData v-model:dictValue="dictValue" :textValue="'字典'" :dict="'字典ID'" :mode="'multiple'" :source="来源"></dictData> -->
  <!-- <dictData v-model:dictValue="Attribute" :textValue="'商品属性'" :source="来源"></dictData> -->
  <div>
    <div v-if="source == 1">
      <span v-if="textValue">{{ textValue }}</span>
      <span v-if="textValue"> ：</span>
      <a-select v-model:value="value" :mode="mode" allowClear placeholder="请选择" style="width: 300px" :options="cities">
      </a-select>
    </div>
    <div v-if="source == 2">
      <div v-for="(item, index) in AttributeList" :key="index" style="border-bottom: 1px dashed rgb(139 157 185); padding: 5px">
        <PlusSquareOutlined style="float: right; cursor: pointer" v-if="index == 0" @click="Add()" />
        <MinusSquareOutlined style="float: right; cursor: pointer" v-else @click="del(index)" />
        主属性：<a-select v-model:value="AttributeList[index].attributeId" allowClear placeholder="请选择" style="width: 200px" @change="handleChange($event, index, true)" :options="optionsFirst" />
        <br />
        <br />
        <span v-if="AttributeList[index].attributeId">附属属性：</span>
        <a-select v-if="AttributeList[index].attributeId" v-model:value="AttributeList[index].children" :mode="'multiple'" allowClear placeholder="请选择" style="width: 400px" :options="AttributeList[index].optionsSecond" />
      </div>
    </div>
  </div>
</template>
<script>
import { getDicts, getAttribute, getAttributeValueController } from '@/api/dict'
export default {
  props: {
    dictValue: {
      type: String
    },
    //名称
    textValue: {
      type: String || Boolean
    },
    //id号
    dict: {
      type: String
    },
    //是否多选，默认单选
    mode: {
      type: String,
      // 多选 multiple
      default: ''
    },
    //source 来源（1：字典，2：商品属性）
    source: {
      type: Number
    }
  },
  data() {
    return {
      cities: [],
      optionsFirst: []
    }
  },
  created() {
    if (this.source == 1) {
      getDicts({ dictId: this.dict }).then((res) => {
        this.cities = res.data
      })
    } else {
      getAttribute().then((res) => {
        this.optionsFirst = res.data.map((res) => {
          return {
            label: res.name,
            value: res.attributeId
          }
        })
      })
    }
  },
  methods: {
    Add() {
      this.AttributeList.push({ attributeId: undefined, children: [] })
    },
    del(index) {
      this.AttributeList.splice(index, 1)
    },
    handleChange(val, index, type) {
      if (type) {
        this.AttributeList[index].children = []
      }
      getAttributeValueController({ attributeId: val }).then((res) => {
        this.AttributeList[index].optionsSecond = res.data.map((res) => {
          return {
            label: res.name,
            value: res.attributeValueId
          }
        })
      })
    }
  },
  computed: {
    value: {
      get() {
        return this.dictValue
      },
      set(value) {
        const data = this.cities.find((item) => item.value === value)
        this.$emit('change', data)
        this.$emit('update:dictValue', value)
      }
    },
    AttributeList: {
      get() {
        this.dictValue.map((res, index) => {
          if (res.attributeId) {
            this.handleChange(res.attributeId, index)
          }
        })
        return this.dictValue
      },
      set(value) {
        this.$emit('update:dictValue', value)
      }
    }
  }
}
</script>
