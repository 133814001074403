import axios from "../axios-config";
//字典
 export function getDicts(params: any) {  
  return axios.get("/api/back/system/DictDetailController/find", {
    params: {
      ...params
    }
})
}
//商品属性
 export function getAttribute(params: any) {  
  return axios.get("/api/back/mall/AttributeController/find", {
    params: {
      ...params
    }
})
}
//商品详情属性
 export function getAttributeValueController(params: any) {  
  return axios.get("/api/back/mall/AttributeValueController/find", {
    params: {
      ...params
    }
})
}