import axios from "../../axios-config";
/**
 * 新增商品
 */
export function GoodsController_post(params: any) {
  return axios.post("/api/back/mall/GoodsController", { ...params });
}

/**
 * 修改商品
 */
export function GoodsController_put(params: any) {
  return axios.put("/api/back/mall/GoodsController", { ...params });
}

/**
 * 删除商品
 */
export function GoodsController_del(params: any) {
  return axios.put("/api/back/mall/GoodsController/updateIsDel",{ ...params });
}

/**
 * 查询商品详情
 */
export function GoodsController_id(id: any) {
  return axios.get("/api/back/mall/GoodsController/"+id);
}
/**
 * 分页查询商品
 */
export function GoodsController_page(params: any) {
  return axios.get("/api/back/mall/GoodsController/page", {
    params: {
      ...params
    }
  });
}
// 查询商品类型树

export function findByTreeList(params: any) {
  return axios.get("/api/back/mall/CategoryController/findByTree", {params:{
    ...params
  }});
}
/**
 * 查询所有的发货地
 */
export function UserAddressController_find(params: any) {
  return axios.get("/api/back/mall/UserAddressController/find", {
    params: {
      ...params
    }
  });
}
/**
 * 查询所有的运费模板
 */
export function FreightTemplatController_find(params: any) {
  return axios.get("/api/back/mall/FreightTemplatController/find", {
    params: {
      ...params
    }
  });
}
/**
 * 查询商品规格大类
 */
export function GoodsSpecController_find(params: any) {
  return axios.get("/api/back/mall/GoodsSpecController/find", {
    params: {
      ...params
    }
  });
}
/**
 * 查询商品规格小类
 */
export function GoodsSpecValueController_find(params: any) {
  return axios.get("/api/back/mall/GoodsSpecValueController/find", {
    params: {
      ...params
    }
  });
}
