
import { defineComponent, reactive, toRefs, ref } from 'vue'
import pagaTable from '@/components/pagination-table/index'
import formSearch from '@/components/page-search/form'
import popupWindow from './popupNew.vue'
import { findByTreeList } from '@/api/commodityManagement/allGoods'
import {
  GoodsController_page,
  GoodsController_del
} from '@/api/commodityManagement/allGoods'
import { message } from 'ant-design-vue'
const purchaseColumns = [
  {
    title: '商品名称',
    dataIndex: 'name',
    width: 300,
    ellipsis: true
  },
  {
    title: '卖点',
    dataIndex: 'sellPoint',
    width: 100,
    ellipsis: true
  },
  {
    title: '商品图片',
    dataIndex: 'attachFile',
    slots: { customRender: 'attachFile' }
  },
  {
    title: '商品类型',
    dataIndex: 'category',
    slots: { customRender: 'category' }
  },
  {
    title: '是否上架',
    dataIndex: 'onSale',
    slots: { customRender: 'onSale' }
  },
  {
    title: '库存',
    dataIndex: 'stock'
  },
  {
    title: '虚拟销量',
    dataIndex: 'salesVolume'
  },
  {
    title: '操作',
    dataIndex: 'operation',
    slots: { customRender: 'operation' }
  }
]
export default defineComponent({
  name: 'questionType',
  components: {
    pagaTable,
    popupWindow,
    formSearch
  },
  setup(props: any) {
    const data = reactive({
      options: [],
      purchaseColumns,
      selectedRowKeys: [],
      extraModel: {},
      formBtn: {
        colProps: { span: 3 },
        formItemProps: {
          wrapperCol: {
            span: 24
          }
        }
      },
      formConfig: [
        {
          colProps: {
            span: 7
          },
          formItemProps: {
            label: '商品名称：'
          },
          comConfig: {
            tag: 'input', // input, select, date-picker
            key: 'name', // 即是插槽也是字段model key
            props: {
              placeholder: '请输入'
            }
          }
        },
        {
          colProps: {
            span: 7
          },
          formItemProps: {
            label: '是否上架：'
          },
          comConfig: {
            tag: 'select',
            key: 'onSale',
            valueKey: 'value',
            labelKey: 'name',
            getDataApi: () => {
              return [
                {
                  name: '上架',
                  value: 1
                },
                {
                  name: '下架',
                  value: 2
                }
              ]
            },
            props: {
              placeholder: '请输入'
            }
          }
        },
        {
          colProps: {
            span: 7
          },
          formItemProps: {
            label: '商品类型：'
          },
          comConfig: {
            tag: 'input',
            key: 'testSlots',
            props: {
              placeholder: '请输入'
            }
          }
        }
      ]
    })
    const onSelectChange = (selectedRowKeys: never[]) => {
      data.selectedRowKeys = selectedRowKeys
    }
    const table = ref()
    const categoryId = ref<any>(undefined)
    const getDataApi = (params: any) => {
      return new Promise((reslove) => {
        const params_api = {
          currentPage: params.pageIndex,
          pageSize: params.pageSize,
          sort: 'createdTime:desc',
          ...formSearch.value.getQuery(),
          categoryId1: categoryId.value
            ? categoryId.value[0]
              ? categoryId.value[0]
              : null
            : null,
          categoryId2: categoryId.value
            ? categoryId.value[1]
              ? categoryId.value[1]
              : null
            : null,
          categoryId3: categoryId.value
            ? categoryId.value[2]
              ? categoryId.value[2]
              : null
            : null
        }
        GoodsController_page(params_api).then((res) => {
          reslove(res)
        })
      })
    }
    const handleClick = (text: string, row: any) => {
      Title.value = '编辑'
      popupWindow.value.showModal(row)
    }
    const formSearch = ref()
    const popupWindow = ref()
    const Title = ref()
    const handleAdd = () => {
      Title.value = '新增商品'
      popupWindow.value.showModal()
    }
    const refresh = () => {
      table.value.refresh()
      data.selectedRowKeys = []
    }
    const del = (val: any) => {
      if (val == 'batch') {
        GoodsController_del({ goodsIds: data.selectedRowKeys }).then((res) => {
          message.success('操作成功')
        })
      } else {
        GoodsController_del({ goodsIds: val }).then((res) => {
          message.success('操作成功')
        })
      }
      refresh()
    }
    const handleSearch = (formState: any) => {
      refresh()
    }
    findByTreeList({}).then((res: any) => {
      data.options = res.data
    })
    return {
      categoryId,
      handleSearch,
      del,
      refresh,
      ...toRefs(data),
      table,
      onSelectChange,
      getDataApi,
      handleClick,
      formSearch,
      popupWindow,
      Title,
      handleAdd
    }
  }
})
