import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "page-wrapper question-type" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }
const _hoisted_6 = { key: 3 }
const _hoisted_7 = { key: 4 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_cascader = _resolveComponent("a-cascader")!
  const _component_formSearch = _resolveComponent("formSearch")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_CheckCircleTwoTone = _resolveComponent("CheckCircleTwoTone")!
  const _component_CloseCircleTwoTone = _resolveComponent("CloseCircleTwoTone")!
  const _component_a_popconfirm = _resolveComponent("a-popconfirm")!
  const _component_pagaTable = _resolveComponent("pagaTable")!
  const _component_popupWindow = _resolveComponent("popupWindow")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_formSearch, {
      ref: "formSearch",
      formConfig: _ctx.formConfig,
      onSearch: _ctx.handleSearch,
      formBtn: _ctx.formBtn
    }, {
      testSlots: _withCtx(() => [
        _createVNode(_component_a_cascader, {
          style: {"width":"100%"},
          value: _ctx.categoryId,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.categoryId) = $event)),
          "field-names": {
                  label: 'name',
                  value: 'categoryId',
                  children: 'children',
                },
          options: _ctx.options,
          placeholder: "请选择",
          "change-on-select": ""
        }, null, 8, ["value", "options"])
      ]),
      _: 1
    }, 8, ["formConfig", "onSearch", "formBtn"]),
    _createVNode(_component_pagaTable, {
      ref: "table",
      columns: _ctx.purchaseColumns,
      sourceData: _ctx.getDataApi,
      "row-selection": { onChange: _ctx.onSelectChange },
      "row-key": "goodsId"
    }, {
      headerLeft: _withCtx(() => [
        _createVNode(_component_a_button, {
          type: "default",
          class: "ant-btn-add",
          onClick: _ctx.handleAdd
        }, {
          default: _withCtx(() => [
            _createTextVNode(" 新增 ")
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_a_button, {
          disabled: !_ctx.selectedRowKeys.length>0,
          type: "default",
          class: "ant-btn-add",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.del('batch')))
        }, {
          default: _withCtx(() => [
            _createTextVNode(" 删除 ")
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createVNode(_component_a_button, {
          disabled: !_ctx.selectedRowKeys.length>0,
          type: "default",
          class: "ant-btn-add"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" 批量上架 ")
          ]),
          _: 1
        }, 8, ["disabled"]),
        _createVNode(_component_a_button, {
          disabled: !_ctx.selectedRowKeys.length>0,
          type: "default",
          class: "ant-btn-add"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" 批量下架 ")
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      attachFile: _withCtx(({ text }) => [
        _createElementVNode("img", {
          src: text.path,
          alt: "",
          style: {"width":"100px","height":"80px"}
        }, null, 8, _hoisted_2)
      ]),
      category: _withCtx(({ record }) => [
        (record.category1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(record.category1), 1))
          : _createCommentVNode("", true),
        (record.category2)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "-"))
          : _createCommentVNode("", true),
        (record.category2)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(record.category2), 1))
          : _createCommentVNode("", true),
        (record.category3)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, "-"))
          : _createCommentVNode("", true),
        (record.category3)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(record.category3), 1))
          : _createCommentVNode("", true)
      ]),
      onSale: _withCtx(({ text }) => [
        (text==1)
          ? (_openBlock(), _createBlock(_component_CheckCircleTwoTone, { key: 0 }))
          : (_openBlock(), _createBlock(_component_CloseCircleTwoTone, { key: 1 }))
      ]),
      operation: _withCtx(({ text, record }) => [
        _createVNode(_component_a_button, {
          type: "link",
          onClick: ($event: any) => (_ctx.handleClick(text, record))
        }, {
          default: _withCtx(() => [
            _createTextVNode("编辑")
          ]),
          _: 2
        }, 1032, ["onClick"]),
        _createVNode(_component_a_popconfirm, {
          title: "确定删除吗",
          "ok-text": "是的",
          "cancel-text": "关闭",
          onConfirm: ($event: any) => (_ctx.del([record.goodsId]))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_button, { type: "link" }, {
              default: _withCtx(() => [
                _createTextVNode("删除")
              ]),
              _: 1
            })
          ]),
          _: 2
        }, 1032, ["onConfirm"])
      ]),
      _: 1
    }, 8, ["columns", "sourceData", "row-selection"]),
    _createVNode(_component_popupWindow, {
      ref: "popupWindow",
      Title: _ctx.Title
    }, null, 8, ["Title"])
  ]))
}