<template>
  <!-- 用法 <wangEditor v-model:comment="comment"></wangEditor> -->
  <div style="border: 1px solid #ccc">
    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
    <Editor style="height: 500px; overflow-y: hidden" v-model="valueHtml" :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" @onChange="onChange" />
  </div>
</template>
<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { useCookies } from 'vue3-cookies'
const { cookies } = useCookies()
export default {
  components: { Editor, Toolbar },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    comment: {
      type: String
    }
  },
  setup(props, context) {
    const toolbarConfig = ref({
      excludeKeys: [
        // 行内代码
        'code',
        // 图片
        'divider',
        'emotion',
        'insertLink',
        'editLink',
        'unLink',
        'viewLink',
        'codeBlock',
        'blockquote',
        'headerSelect',
        'header1',
        'header2',
        'header3',
        'header4',
        'header5',
        'todo',
        'redo',
        'undo',
        'fullScreen',
        'enter',
        'bulletedList',
        'numberedList',
        'insertVideo',
        'uploadVideo',
        'editVideoSize',
        'codeSelectLang'
      ]
    })
    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()

    // 内容 HTML
    const valueHtml = ref('')
    const editorConfig = { placeholder: '请输入内容...' }
    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor // 一定要用 Object.seal() ，否则会报错
      // 上传地址
      editorRef.value.getConfig().MENU_CONF['uploadImage'] = {
        server: process.env.VUE_APP_API_BASE_URL + '/storage/upload/image',
        // form-data fieldName ，默认值 'wangeditor-uploaded-image'
        fieldName: 'file',

        // 单个文件的最大体积限制，默认为 2M
        maxFileSize: 2 * 1024 * 1024, // 2M

        // 最多可上传几个文件，默认为 100
        maxNumberOfFiles: 10,

        // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
        allowedFileTypes: ['image/*'],

        // 自定义上传参数，例如传递验证的 token 等。参数会被添加到 formData 中，一起上传到服务端。
        meta: {
          // token: VueCookeis.get(ACCESS_TOKEN)
        },

        // 将 meta 拼接到 url 参数中，默认 false
        metaWithUrl: false,

        // 自定义增加 http  header
        headers: {
          Accept: 'application/json, text/plain, */*',
          Authorization: cookies.get('TOKEN_ACCOUNT')
            ? 'Bearer ' + cookies.get('TOKEN_ACCOUNT')
            : null
        },

        // 跨域是否传递 cookie ，默认为 false
        withCredentials: false,

        // 超时时间，默认为 10 秒
        timeout: 5 * 1000, // 5 秒
        customInsert(res, insertFn) {
          insertFn(res.data.fullPath, res.data.name)
        }
      }
    }
    const onChange = () => {
      context.emit('update:comment', valueHtml.value)
    }
    return {
      onChange,
      editorRef,
      valueHtml,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated
    }
  },
  watch: {
    comment: {
      immediate: true,
      handler(val) {
        if (val) {
          this.valueHtml = val
        }
      }
    }
  }
}
</script>
